$white: #ffffff;
$brand-red: red;
$green-green1: #04cea4;

$bg-primary:#00a2ff; // Main brand color
$text-primary:#232735;
$border-primary:#d0d0d0;

$bg-secondary:#006FBB; 
$text-secondary:#666a73;
$border-secondary:#006FBB;

$bg-tertiary:#d0d0d0; 
$text-tertiary:#006FBB;
$border-tertiary:#006FBB;

$bg-quaternary:#006FBB; 
$text-quaternary:#006FBB;
$border-quaternary:#006FBB;

$bg-quinary:#006FBB; 
$text-quinary:#006FBB;
$border-quinary:#006FBB;


$brand-p2: #00a2ff;
$brand-gray1: #232735; // Text color
$brand-gray2: #f8f8f8; // Background color
$brand-gray3: #dde6e9;
$brand-gray4: #e4eaec;
$brand-gray5: #edf1f2;
$brand-gray6: #4D5055; // Text color
$brand-gray7: #666a73;
$brand-gray8: #d0d0d0; //Border color
$brand-gray9: #b8b298;
$datable-grey: #f9f5f1;
$datatable-black: #444;
$link-color: #00a2ff;
$red-color: #bf0711;

$body-bg: $white !default;
//Set the font-size for the system typography
$h1-size: 2rem;
$h2-size: 1.375rem;
$h3-size: 1.125rem;
$h4-size: 1rem;
$h5-size: .875rem;
$h6-size: 0.75rem;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
$grid-row-columns: 6 !default;
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (xs: 0, // eXtra Small
  sm: 320px, // SMall
  md: 767px, // MeDium
  lg: 1440px, // LarGe
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (sm: 720px,
  md: 1024px,
  lg: 1280px,
) !default;


$sidebar-width:180px;
$header-height:25px;; // height of the header

$spacer:("0", "1", "2", "5", "10", "15", "20", "25", "30", "35", "50","60","80");

// Font family names
$mlveda_think_fontFamily: 'Proxima Nova Thin';
$mlveda_reg_fontFamily: 'Proxima Nova Rg Regular';
$mlveda_bold_fontFamily: 'Proxima Nova Bold';