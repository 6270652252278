@font-face {
  font-family: 'proxima-nova';
  src:url('/assets/fonts/FontsFree-Net-Proxima-Nova-Thin.otf?agusew');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src:url('/assets/fonts/FontsFree-Net-proxima_nova_reg-webfont.ttf?agusew') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src:url('/assets/fonts/FontsFree-Net-Proxima-Nova-Bold.otf?agusew');
  font-weight: 700;
  font-style: normal;
}
