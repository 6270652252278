
@import 'shared.scss';

.custom-select__multi-value {
  background: $white !important;
  border: 1px solid $bg-primary;
}

.custom-select__multi-value__remove:hover {
  background-color: $brand-gray2 !important;
}

.custom-select__control {
  border-radius: 2px !important;
  box-shadow: none !important;
  background: $white !important;
  border: 1px solid $border-primary !important;
  padding: 2px 15px !important;
  font-size: 16px !important;
  line-height: 26px !important;
  color: $brand-gray6 !important;
}

.custom-select__control--is-focused {
  border: 1px solid $bg-primary !important;
  cursor: pointer !important;
}

.custom-select__indicator-separator {
  display: none !important;
}

.custom-select__menu {
  margin-top: 0px !important;
  border-radius: 0px !important;
  border: 1px solid $border-primary !important;
  border-top: 1px solid #f1f1f1 !important;
  box-shadow: none !important;
}

.custom-select__indicator {
  color: $text-primary !important;
  align-items: flex-end !important;
  padding-right: 0px !important;
}

.custom-select__option {
  color: $brand-gray6 !important;
  font-size: 16px !important;
  padding: 12px 15px !important;
}

.custom-select__option--is-selected {
  background-color: $brand-gray2 !important;
  color: $text-primary !important;
}

.custom-select__option--is-focused {
  background-color: $brand-gray2 !important;
  color: $text-primary !important
}

.custom-select__value-container {
  padding: 0px !important;
  height: 26px !important;
  padding-left: 5px !important;
  vertical-align: middle;
}

.custom-select__value-container--has-value {
  height: 26px !important;
  padding-left: 5px !important;
  vertical-align: middle;
}

.custom-select__value-container--is-multi {
  max-height: 150px !important;
  overflow: auto !important;
  height: auto !important;
  margin: -5px -20px -5px 0px;
  padding: 5px 0px !important;
}

.custom-select__multi-value:nth-child(1) {
  background: $brand-gray2 !important;
}

.custom-select__value-container--is-multi+.custom-select-box__indicators {
  align-items: flex-end !important;
  display: none !important;
}





.select-checkbox {
  display: none;
}

.select-checkbox:checked+.select-checkbox-label {
  background: $bg-primary;
}

.select-checkbox:checked+.select-checkbox-label:after {
  content: '';
  position: absolute;
  left: 2px;
  top: 6px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

.select-checkbox-label {
  /* Style for checkbox normal */
  width: 16px;
  height: 16px;
  display: block;
  background: $brand-gray2;
  border-radius: 2px;
  position: relative;
  border: 1px solid $bg-primary;
}

.select-checkbox-label>span {
  display: inline-block;
  padding-left: 25px;
  margin-top: -4px;
  position: absolute;
  width: 350px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.select-checkbox-label.checked {
  background: $bg-primary;
}

.select-checkbox-label.checked:after {
  content: '';
  position: absolute;
  left: 4px;
  top: 7px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}