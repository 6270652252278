@import '../../../styles/_shared';

.timer-popup-first-section {
  display: table;
  width: 100%;
  & img {
    height: 100px;
    display: table-cell;
  }
  & .title {
    display: table-cell;
    vertical-align: middle;
    font-size: 1.8rem;
    line-height: 2.3rem;
    padding-left: 15px;
  }
}

.timer-popup-second-section {
  margin-top: 20px;
  font-size: 4rem;
  font-weight: 600;
  color: $green-green1;
  text-align: center;
  & span {
    font-size: 2rem;
    margin-left: 3px;
  }
}

.timer-popup-third-section {
  margin-top: 20px;
  color: $bg-primary;
  & span {
    color: $green-green1;
  }
}

.timer-popup-fourth-section {
  margin-top: 10px;
  & .link {
    color: $link-color;
    cursor: default;
  }
  & .underline {
    text-decoration: underline;
  }
}

.timer-popup-fifth-section {
  margin-top: 20px;
}

.timer-popup-sixth-section {
  display: table;
  width: 100%;
  margin-top: 20px;
  & .data {
    display: inline;
    margin-right: 20px;
    font-weight: 600;
    color: $green-green1;
  }
  & .btn {
    display: inline;
  }
}

.contact-us {
  margin-top: 20px;
  & .link {
    color: $link-color;
    cursor: default;
  }
}

.success-svg {
  width: 100px;
  display: block;
  margin: 0 auto;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  &.circle {
    -webkit-animation: dash 1.5s ease-in-out;
    animation: dash 1.5s ease-in-out;
  }
  &.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 1.5s .5s ease-in-out forwards;
    animation: dash 1.5s .5s ease-in-out forwards;
  }
  &.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 1.5s .5s ease-in-out forwards;
    animation: dash-check 1.5s .5s ease-in-out forwards;
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}