@import '../variables';
@import './breakpoints';
/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container($gutter: $grid-gutter-width) {
  width: 100%;
  padding-right: $gutter / 2;
  padding-left: $gutter / 2;
  margin-right: auto;
  margin-left: auto;
}


// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {

  @each $breakpoint,
  $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-row($gutter: $grid-gutter-width) {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$gutter / 2;
  margin-left: -$gutter / 2;
}

@mixin make-col-ready($gutter: $grid-gutter-width) {
  position: relative;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  width: 100%;
  padding-right: $gutter / 2;
  padding-left: $gutter / 2;
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 percentage($size / $columns);
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: percentage($size / $columns);
}

@mixin make-col-auto() {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; // Reset earlier grid tiers
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: $size / $columns;
  margin-left: if($num==0, 0, percentage($num));
}

// Row columns
//
// Specify on a parent element(e.g., .row) to force immediate children into NN
// numberof columns. Supports wrapping to new lines, but does not do a Masonry
// style grid.
@mixin row-cols($count) {
  &>* {
    flex: 0 0 100% / $count;
    max-width: 100% / $count;
  }
}



// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {

  // Common properties for all breakpoints
  %grid-column {
    position: relative;
    width: 100%;
    padding-right: $gutter / 2;
    padding-left: $gutter / 2;
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    // Allow columns to stretch full width below their breakpoints
    @for $i from 1 through $columns {
      .cl#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }

    .cl#{$infix},
    .cl#{$infix}-auto {
      @extend %grid-column;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {

      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .cl#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      @for $i from 1 through $grid-row-columns {
        .row-cls#{$infix}-#{$i} {
          @include row-cols($i);
        }
      }

      .cl#{$infix}-auto {
        @include make-col-auto();
      }

      @for $i from 1 through $columns {
        .cl#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }

      .order#{$infix}-first {
        order: -1;
      }

      .order#{$infix}-last {
        order: $columns + 1;
      }

      @for $i from 0 through $columns {
        .order#{$infix}-#{$i} {
          order: $i;
        }
      }

      // `$columns - 1` because offsetting by the width of an entire row isn't possible
      @for $i from 0 through ($columns - 1) {
        @if not ($infix==""and $i==0) {

          // Avoid emitting useless .offset-0
          .offset#{$infix}-#{$i} {
            @include make-col-offset($i, $columns);
          }
        }
      }
    }
  }
}



@mixin spacing($breakpoints: $grid-breakpoints) {
  .ml-auto{
    margin-left: auto;
  }
  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      .mx#{$infix}-auto {
        margin-left: auto;
        margin-right: auto;
      }
      .my#{$infix}-auto {
        margin-top: auto;
        margin-bottom: auto;
      }
      @each $i in $spacer {
        .mt#{$infix}-#{$i} {
          margin-top:#{$i}px;
        }
        .ml#{$infix}-#{$i} {
          margin-left:#{$i}px;
        }
        .mb#{$infix}-#{$i} {
          margin-bottom:#{$i}px;
        }
        .mr#{$infix}-#{$i} {
          margin-right:#{$i}px;
        }
        .mx#{$infix}-#{$i} {
          margin-left:#{$i}px;
          margin-right:#{$i}px;
        }
        .my#{$infix}-#{$i} {
          margin-top:#{$i}px;
          margin-bottom:#{$i}px;
        }
        .m#{$infix}-#{$i} {
          margin:#{$i}px;
        }

        .pt#{$infix}-#{$i} {
          padding-top:#{$i}px;
        }
        .pl#{$infix}-#{$i} {
          padding-left:#{$i}px;
        }
        .pb#{$infix}-#{$i} {
          padding-bottom:#{$i}px;
        }
        .pr#{$infix}-#{$i} {
          padding-bottom:#{$i}px;
        }
        .px#{$infix}-#{$i} {
          padding-left:#{$i}px;
          padding-right:#{$i}px;
        }
        .py#{$infix}-#{$i} {
          padding-top:#{$i}px;
          padding-bottom:#{$i}px;
        }
        .p#{$infix}-#{$i} {
          padding:#{$i}px;
        }
      }

    }

  }
}