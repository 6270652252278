@import 'shared.scss';
@import 'fonts.scss';
@import 'typo.scss';
@import 'grid.scss';
@import 'flex-box.scss';
@import 'dropdown.scss';
@import 'pagination.scss';
@import 'card.scss';

@font-face {
	font-family: $mlveda_think_fontFamily;
	font-style: normal;
	font-weight: normal;
	src: local('Proxima Nova Thin'), url('../../src/assets/fonts/FontsFree-Net-Proxima-Nova-Thin.otf') format('woff');
}

@font-face {
	font-family: $mlveda_reg_fontFamily;
	font-style: normal;
	font-weight: normal;
	src: local('Proxima Nova Rg Regular'),
		url('../../src/assets/fonts/FontsFree-Net-proxima_nova_reg-webfont.ttf') format('woff');
}

@font-face {
	font-family: $mlveda_bold_fontFamily;
	font-style: normal;
	font-weight: normal;
	src: local('Proxima Nova Bold'), url('../../src/assets/fonts/FontsFree-Net-Proxima-Nova-Bold.otf') format('woff');
}

html {
  // font-size: 16px!important;
  width: 100%;
  margin: 0;
  height: 100%;
}

body {
  height: 100%;
  font-family: $mlveda_reg_fontFamily, sans-serif !important;
  @include webkit-scroller;
  line-height: 2rem;
  background-color: $body-bg;
  color: $text-primary;
}

#root {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0px;
  margin: 0px;
}

/* HTML ELEMENTS CUSTOM CSS */
* {
  box-sizing: border-box;

  &:after,
  &:before {
    box-sizing: border-box;
  }
}

a,
a:active,
a:focus,
a:hover,
button,
button:active,
button:focus,
button:hover {
  outline: none;
}

.is_size_h1 {
  color: red;
}

.react-grid-Cell {
  padding: 0px !important;
}

.rdg-cell-expand {
  top: 5px !important;
  right: 15px !important;
}

.react-grid-Cell__value {
  border-bottom: 1px solid #ddd;
}

.react-grid-Cell__value>div:nth-child(2) {
  margin-left: 0px !important;
}

.react-grid-Cell__value>div:nth-child(2)>span>div {
  margin-left: 0px !important;
  padding-left: 25px !important;
}


.ag-full-width-row {
  background: #f7f7f7 !important;
}

.ag-header-icon.ag-header-cell-menu-button {
  display: none;
}

.ag-header-group-cell-label {
  justify-content: center;
}

.ag-theme-alpine .Polaris-Banner {
  box-shadow: var(--p-banner-border, inset 0 3px 0 0 var(--p-override-none, #de3618), inset 0 0 0 0 transparent, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)) !important;
  color: #bf0711;
  & .Polaris-DisplayText--sizeSmall {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  & .Polaris-Link {
    margin-left: 15px;
    outline: none;
    color: $bg-primary;
  }
}
.ag-theme-alpine .Polaris-Banner__Ribbon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
}

.ag-theme-alpine .ag-header-cell-resize::after {
  top: 0px !important;
  height: 100% !important;
}

.ag-theme-alpine .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle {
  border-color: #2196f3 !important;
}

.ag-theme-alpine .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-left {
  border-left-color: #2196f3 !important;
  border-left-color: var(--ag-range-selection-border-color, #2196f3) !important;
}

.ag-theme-alpine .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-right {
  border-right-color: #2196f3 !important;
  ;
  border-right-color: var(--ag-range-selection-border-color, #2196f3) !important;
}

.ag-theme-alpine .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-top {
  border-top-color: #2196f3 !important;
  ;
  border-top-color: var(--ag-range-selection-border-color, #2196f3) !important;
}

.ag-theme-alpine .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-bottom {
  border-bottom-color: #2196f3 !important;
  ;
  border-bottom-color: var(--ag-range-selection-border-color, #2196f3) !important;
}

.ag-theme-alpine .ag-ltr .ag-cell.ag-selection-fill-left {
  border-left: 1px dashed !important;
  border-left-color: #2196f3 !important;
  border-left-color: var(--ag-range-selection-border-color, #2196f3) !important;
}

.ag-theme-alpine .ag-ltr .ag-cell.ag-selection-fill-right {
  border-right: 1px dashed !important;
  border-right-color: #2196f3 !important;
  border-right-color: var(--ag-range-selection-border-color, #2196f3) !important;
}

.ag-theme-alpine .ag-ltr .ag-cell.ag-selection-fill-bottom {
  border-bottom: 1px dashed !important;
  border-bottom-color: #2196f3 !important;
  border-bottom-color: var(--ag-range-selection-border-color, #2196f3) !important;
}

.ag-theme-alpine .ag-ltr .ag-cell.ag-selection-fill-top {
  border-top: 1px dashed !important;
  border-top-color: #2196f3 !important;
  border-top-color: var(--ag-range-selection-border-color, #2196f3) !important;
}

.ag-cell.disabled {
  background-color: #f7f7f7;
  cursor: not-allowed;
}

.text-align-center {
  @include text-align(center);
}

// Override Shopify Polaris styles
.Polaris-Page {
  padding: 0;
}

.Polaris-Page-Header {
  padding: 0;
}

// .Polaris-Frame {
//   background: none;
//   height: 0px !important;
//   width: 0px !important;
// }

// .Polaris-Frame-Loading {
//   height: 5px;
// }

// .Polaris-Frame-Loading__Level {
//   background-color: $bg-primary;
// }

// css for toggle switch
.switch-wrapper.on {
  color: #14c6a2;
  border: 1px solid #00c19a;
  background-color: #fff;
}

.switch-wrapper.off {
  color: #fff;
  border: 1px solid #a8a8a8;
  background-color: #a8a8a8;
  position: relative;
}

.switch-wrapper.off .is.on {
  left: -100%;
}

.switch-wrapper.off .is.off {
  margin-right: -6px;
}

.switch-wrapper {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1;
  word-wrap: normal;
  word-break: normal;
}

.switch-wrapper.on .is.on {
  left: 0;
}

.switch-wrapper .is {
  margin: 0 2px;
  padding: 9px 30px 7px 10px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  transition: left 0.2s linear, margin 0.2s linear;
}

.switch-wrapper.on .is.off {
  margin-right: 26px;
}

.switch-wrapper .is.off {
  right: -99%;
  padding-left: 25px;
  position: absolute;
  top: 0;
}

.switch-wrapper .is.off:before {
  position: absolute;
  left: 0;
  top: 6px;
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.switch-wrapper.on .is:before {
  background: #00c19a;
}

.switch-wrapper.off .is:before {
  background: #fff;
}

// css added by Kavisha

body,
.Polaris-DataTable__Heading,
.Polaris-Choice__Label,
.Polaris-Choice__HelpText,
.Polaris-Button__Text,
.Polaris-Select__Input option {
	font-family: $mlveda_reg_fontFamily;
	font-size: 1.55rem;
}

body,
.Polaris-Frame__Content {
	background: white;
}

.Polaris-Frame {
	min-height: unset;
}

.Polaris-Frame-Toast {
	color: white;
}

.app {
  position: relative;
	& .Polaris-TopBar {
    margin: 0 auto;
    background: #fff;
		& .Polaris-TopBar__ContextControl {
			// padding-left: 20px;

			& > div {
				height: 100%;
				cursor: pointer;
			}

			& .Polaris-Stack__Item:first-child {
				margin-left: 0;
			}
		}

		// @media (min-width: 1360px) {
		// 	max-width: 1084.88px;
		// }

		// @media (min-width: 1280px) and (max-width: 1359px) {
		// 	max-width: 1068px;
		// }

		// @media (max-width: 1279px) {
		// 	max-width: 992px;
		// }
	}

	&__contexualSaveBar__titleText {
		font-weight: 700;
		font-size: 1.6rem;
	}

	& .Polaris-Frame-ContextualSaveBar__ContextControl {
		& img {
			width: 30px;
		}

		& > div {
			height: 100%;
			margin: 0;

			& > .Polaris-Stack {
				height: 100%;
			}
		}
	}

	& .__container {
		padding-bottom: 50px;
	}
}

#google_translate_element {
  margin-right: 20px;
}

.faq-header {
  & .Polaris-Icon {
    svg {
      fill: $bg-primary;
    }
  }
}

.welcome-footer-btn-main {
  width: 100%;
  text-align: center;
  margin-top: 25px;
  & .Polaris-Button {
    width: 55%;
  }
}

.onboarding-footer-btn-main {
  width: 100%;
  margin-top: 25px;
}

.h2, h2 {
  font-size: 2rem;
  line-height: 2.8rem;
  font-family: $mlveda_bold_fontFamily;
}

.Polaris-Card {
  box-shadow: none;
  border: 2px solid $brand-gray8;
  & .Polaris-Card__Section {
    padding: 3rem;
  }
}

.Polaris-Modal-Section {
	padding: 3rem 4rem;
}

.ag-header-group-cell {
  justify-content: center;
}

.ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell {
  padding-left: 10px;
  padding-right: 10px;
}

.pricing-container {
  max-width: 100%;
  padding-bottom: 0px !important;
  height: 100%;
}

h1.Polaris-Heading {
  font-size: 2rem;
  line-height: 3.2rem;
  font-weight: 400;
}

.Polaris-Button--plain.Polaris-Button--iconOnly svg {
  fill: $brand-gray8;
}

.Polaris-DataTable__Table thead {
  background: $datable-grey;
  font-weight: 900 !important;
  & th {
    font-weight: 600;
    font-size: 1.55rem;
    font-family: $mlveda_reg_fontFamily;
    color: $datatable-black;
  }
}

.ml-10 {
  margin-left: 10px;
}

.market-container {
  height: calc(100vh - 147px);
  max-width: 950px;
  margin: 0 auto;
  margin-bottom: 150px;
  & .Polaris-Heading {
    font-weight: 400 !important;
  }
  & .marketBanner {
    margin-top: 30px;
    & .Polaris-Banner {
      margin: 0 auto;
      margin-bottom: 20px;
      box-shadow: none;
      & .Polaris-Link {
        text-decoration: none;
        outline: none !important;
        color: $bg-primary !important;
      }
      & .Polaris-Link:hover {
        text-decoration: underline;
      }
      & .Polaris-Banner__Ribbon {
        display: none;
      }
    }
  }
  & .marketContactUsBanner {
    & .Polaris-Banner {
      width: 85%;
      margin: 0 auto;
      margin-bottom: 20px;
      box-shadow: none;
      & .Polaris-Link {
        text-decoration: none;
        outline: none !important;
        color: $bg-primary !important;
      }
      & .Polaris-Link:hover {
        text-decoration: underline;
      }
      & .Polaris-Banner__Ribbon {
        display: none;
      }
      & .Polaris-Heading{
        font-size: 1.8rem;
        font-weight: 600 !important;
      }
    }
  }
  & .Polaris-Page {
    width: 85%;
  }
  .Polaris-DataTable__Cell {
    padding: 1rem 1.1rem;
    border-bottom: 1px solid $brand-gray8;
    white-space: pre-wrap;
    vertical-align: middle;
    & .Polaris-Link {
      vertical-align: -webkit-baseline-middle;
    }
    & .Polaris-Button__Text {
      font-size: 1.45rem;
    }
  }
  & .Polaris-DataTable__Table {
    table-layout: fixed;
    & .Polaris-Icon {
      height: 1.2rem;
      width: 1.2rem;
      & svg {
        fill: $bg-primary
      }
    }
  }
  & .Polaris-DataTable__Cell--header:nth-child(1) {
    width: 5%;
  }
  & .Polaris-DataTable__Cell--header:nth-child(2) {
    width: 44%;
  }
  & .Polaris-DataTable__Cell--header:nth-child(3) {
    width: 16%;
  }
  & .Polaris-DataTable__Cell--header:nth-child(4) {
    width: 21%;
  }
  & .Polaris-DataTable__Cell--header:nth-child(5) {
    width: 10%;
  } 
  & .Polaris-DataTable__TableRow .Polaris-DataTable__Cell:nth-child(2) {
    color: $bg-primary;
    font-size: 1.8rem;
  }
  & .Polaris-TextStyle--variationStrong {
    font-weight: 100;
  }
  & .Polaris-TextField__Input:disabled {
    color: $brand-gray1;
  }
  & .textField {
    border: 1px solid $brand-gray8;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 2rem;
    min-width: 3.6rem;
    border-radius: 3px;
    padding: 0.5rem 1rem;
    vertical-align: middle;
  }
  & .displayCurrencyInTable {
    & .textField {
      display: inline-block;
    }
    & .tooltipSpan {
      display: inline-block;
      padding-left: 10px;
      cursor: pointer;
      vertical-align: middle;
      & svg {
        fill: #555;
      }
    }
  }
}

.createMarketOnboarding {
  & .Polaris-Button {
    width: 50%;
    margin: 0 auto;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.container {
  padding: 25px 0px;
  padding-bottom: 65px;
  min-height: calc(100vh - 57px);
}

.mt-40 {
  margin-top: 40px;
}

.mt-15 {
  margin-top: 15px;
}

.staff-access-container {
  .Polaris-TextContainer > *:not(:first-child) {
    margin-top: 2.4rem;
  }
}

.timer-edit-btn {
  display: inline;
  & .Polaris-Button  {
    padding: 5px;
    min-height: auto;
    min-width: auto;
  }
  & .Polaris-Icon {
    height: 1.2rem;
    width: 1.2rem;
  }
}

.rounding-rule-container {
  & .Polaris-Layout__AnnotationContent {
    border: 2px solid $brand-gray8;
    & .Polaris-Card {
      border: none;
      border-bottom: 1px solid $brand-gray8;
      & .Polaris-Card__Section {
        padding: 1.5rem;
      }
    }
    & .Polaris-Card + .Polaris-Card {
      margin-top: 0px;
    }
    & .Polaris-Stack {
      align-items: center;
    }
    & .Polaris-Stack--distributionCenter {
      padding: 1rem 0rem;
    }
    & .Polaris-TextField--disabled {
      color: $brand-gray1;
    }
    & .mamagedBySP {
      color: $green-green1;
      & span:focus {
        outline: none;
      }
      & .Polaris-Icon {
        display: inline-block;
        vertical-align: middle;
        height: 1.5rem;
        width: 1.5rem;
        margin-left: 8px;
        & svg {
          fill: #04cea48a;
        }
      }
    }
  }
}

.mediumFontSized {
  font-size: 1.6rem;
}

.plainFonts {
  font-weight: 400;
}

.bold-font {
  font-family: $mlveda_bold_fontFamily;
}

.Polaris-Modal-CloseButton {
  display: none;
}

.Polaris-Button {
  height: 4.5rem;
  // & .Polaris-Button__Text {
  //   font-weight: 600;
  // }
	&:not(.Polaris-Button--plain):not(.Polaris-Button--primary):not(.Polaris-Button--destructive):not(.Polaris-Button--outline) {
		color: $brand-gray1;
	}

	&:not(.Polaris-Button--plain):not(.Polaris-Button--primary):not(.Polaris-Button--destructive):not(.Polaris-Button--outline) {
		&:hover,
		&:focus {
			background: $bg-primary;
			color: white;
			border: 1px solid $bg-primary;
			box-shadow: none;
		}
	}

	&--primary {
		&.Polaris-Button--plain {
			color: $bg-primary;
			background: white;
			border: none;
			box-shadow: none;
    }
    
    &.Polaris-Button--disabled {
      opacity: 0.5;
    }

		&.Polaris-Button--outline:not(.Polaris-Button--plain) {
			border: 1px solid $bg-primary;
			color: $bg-primary;
			background: white;
			box-shadow: none;

			&:hover {
				background: $bg-primary;
				color: white;
				box-shadow: none;
			}
		}

		&:not(.Polaris-Button--outline):not(.Polaris-Button--plain) {
			background: $bg-primary;
			color: white;
			border: 1px solid $bg-primary;
			box-shadow: none;

			&:hover,
			&:focus {
				// background: $bg-primary;
				opacity: .8;
				// color: white;
				// border: none;
				// box-shadow: none;
			}
		}
	}

	&--outline:not(.Polaris-Button--primary):not(.Polaris-Button--destructive):not(.Polaris-Button--plain):hover {
		border: none;
		box-shadow: none;
		background: $bg-primary;
		color: white;
	}
}

.Polaris-Button--plain {
  color: $bg-primary;
  & .Polaris-Button__Text {
    font-weight: 100;
  }
}

.Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Icon,
.Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage)
	.Polaris-RadioButton__Input:focus
	+ .Polaris-RadioButton__Backdrop,
.Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage)
	.Polaris-RadioButton__Input:active
	+ .Polaris-RadioButton__Backdrop,
.Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage)
	.Polaris-RadioButton__Input:checked
	+ .Polaris-RadioButton__Backdrop {
	background: $bg-primary;
	box-shadow: 0 0 0 1px $bg-primary, 0 0 0 0 transparent;
}

.Polaris-Checkbox__Icon .Polaris-Icon {
	vertical-align: middle;
}

.Polaris-Checkbox .Polaris-Icon svg {
	fill: $bg-primary;
}

.Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage)
	.Polaris-Checkbox__Input:focus
	+ .Polaris-Checkbox__Backdrop,
.Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage)
	.Polaris-Checkbox__Input:active
	+ .Polaris-Checkbox__Backdrop,
.Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage)
	.Polaris-Checkbox__Input:checked
	+ .Polaris-Checkbox__Backdrop,
.Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage)
	.Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate
	+ .Polaris-Checkbox__Backdrop {
	background: $bg-primary;
	box-shadow: 0 0 0 1px $bg-primary, 0 0 0 0 transparent;
}

.Polaris-TextField--focus .Polaris-TextField__Backdrop {
	border-color: transparent;
	box-shadow: none;
}

.Polaris-TextField__Backdrop::after {
	border: .1rem solid $bg-primary;
}

.Polaris-Link, .Polaris-Link:hover {
  color: $bg-primary;
}

.Polaris-Select__Icon {
	.Polaris-Icon {
		vertical-align: middle;
	}
}

.Polaris-Select:not(.Polaris-Select--newDesignLanguage) .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop {
	background: $bg-primary;
	box-shadow: 0 0 0 1px $bg-primary, 0 0 0 0 transparent;
	border: none;
}

.Polaris-Labelled__HelpText {
	font-size: 1.55rem;
}
.Polaris-Label label {
	font-weight: 600;
	font-size: 1.55rem;
}

.Polaris-Label {
  & .Polaris-Label__Text {
    & .text-align-center {
      text-align: left;
    }
  }
}

.Polaris-OptionList-Checkbox__Icon .Polaris-Icon svg {
  fill: $bg-primary !important;
}

.Polaris-OptionList-Checkbox__Backdrop {
  border-color: $bg-primary !important;
}

.Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage)
  .Polaris-OptionList-Checkbox__Input:focus
  + .Polaris-OptionList-Checkbox__Backdrop,
.Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage)
  .Polaris-OptionList-Checkbox__Input:active
  + .Polaris-OptionList-Checkbox__Backdrop {
  background: $bg-primary;
  box-shadow: 0 0 0 1px $bg-primary, 0 0 0 0 transparent;
  border-color: transparent;
}

.Polaris-Labelled__LabelWrapper {
  margin-bottom: .8rem;
}

.Polaris-FooterHelp {
  margin-top: 40px;
  & .Polaris-FooterHelp__Icon {
    display: none;
  }
  & .Polaris-FooterHelp__Content {
    background: $datable-grey;
  }
  & .main-div {
    display: table;
    width: 300px;
    & img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      display: table-cell;
    }
    & div {
      display: table-cell;
      vertical-align: middle;
      padding-left: 15px;
      line-height: 1.2;
    }
  }
}

.Polaris-Pagination__Button svg {
  fill: $bg-primary;
}

.Polaris-Pagination__NextButton, .Polaris-Pagination__PreviousButton, .Polaris-Pagination__Button:hover {
  border-color: $bg-primary;
}

.Polaris-Pagination__PreviousButton:disabled {
  border-color: inherit;
}

.Polaris-Banner {
  border: 1px solid $brand-gray8;
  box-shadow: none !important;
  padding: 1.2rem;
}

.app-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: $datable-grey;
  padding: 10px;
  height: 40px;
}

.app-footer-pricing {
  display: none;
}

.ag-header-group-cell .ag-react-container span {
  white-space: normal;
}

.underline {
  text-decoration: underline;
  text-underline-position: under;
}

.emailLink {
  color: $bg-primary;
  cursor: default;
}

.resolveNowBtn {
  & button {
    height: 3rem;
    margin-left: 15px;
  }
}

.staffAccessContactMsg {
  margin-top: 20px;
  border-top: 1px solid $border-primary;
  padding-top: 20px;
  font-size: 14px;
}

.link:hover {
  text-decoration: underline;;
}

.success-svg {
  width: 100px;
  display: block;
  margin: 0 auto;
}

.extraEmail {
  & .infoIcon {
    & .Polaris-Icon {
      height: 6rem;
      width: 6rem;
      margin-bottom: 25px;
      & svg {
        fill: $bg-primary;
      }
    }
  }
  & .Polaris-TextField {
    width: 60%;
  }
}

.deleteApp {
  & .Polaris-Button {
    background: white !important;
    border: 1px solid $red-color !important;
    color: $red-color !important;
  }
  & .Polaris-Button:hover {
    background: white !important;
    border: 1px solid $red-color !important;
    color: $red-color !important;
  }
}

.marketDeleteBtn {
  & .Polaris-Button {
    border: 0px !important;
  }
  & .Polaris-Button:hover {
    background: white !important;
    color: $red-color !important;
  }
  & .Polaris-Button--monochrome.Polaris-Button--outline::before {
    background: white !important;
  }
}

.marketformPrimaryBtn {
  & .Polaris-Button {
    padding: 0.7rem 4.5rem;
  }
}

.red-color {
  color: $red-color;
}

.blue-color {
  color: $bg-primary;
}

.freezeTab {
  & .Polaris-Icon {
    & svg {
      fill: $brand-gray8 !important;
    }
  }
  & .Polaris-Stack__Item {
    span {
      color: $brand-gray8;
    }
  }
}

.deleteConfirmation {
  & .Polaris-Icon {
    height: 6rem;
    width: 6rem;
    margin-bottom: 15px;
  }
}

.inventoryLabel {
  & span:focus {
    outline: none;
  }
  & .Polaris-Icon {
    display: inline-block;
    vertical-align: middle;
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 8px;
    cursor: default;
    & svg {
      fill: #2327357d;
    }
  }
}

.collaborativeCode {
  margin-top: 10px;
  margin-left: 2.5rem;
  & .Polaris-TextField {
    width: 70%;
    margin-top: 10px;
  }
  & .Polaris-Link:focus {
    outline: none;
    text-decoration: none;
  }
  & .Polaris-Link:hover {
    text-decoration: none;
  }
  & .Polaris-Link {
    & .Polaris-Icon {
      display: inline-block;
      vertical-align: middle;
      height: 1.6rem;
      width: 1.6rem;
      margin-left: 3px;
      & svg {
        fill: $bg-primary;
      }
    }
  }
}

.priceHeader {
  & span:focus {
    outline: none;
  }
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 5px;
  vertical-align: middle;
  & svg {
    fill: #2327357d;
  }
}

.restOfWorldMessage {
  & span:focus {
    outline: none;
  }
  & .Polaris-Icon {
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 5px;
    vertical-align: middle;
  }
  & svg {
    fill: #2327357d;
  }
}

.variantImg {
  vertical-align: middle;
  margin-right: 5px;
  & img {
    height: 25px;
    width: 25px;
    vertical-align: middle;
  }
}

.productHeader {
  & .roundedTo {
    font-weight: 400;
  }
}

.ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.bulkDownload {
  & .Polaris-Stack__Item:first-child {
    flex: 1;
    color: #637381;
    padding-right: 20px;
  }
  & .Polaris-Stack__Item:last-child {
    align-self: center;
  }
}

.roundingImg {
  width: 100%;
}

.impMsgBanner {
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #fa233b;
  padding: 30px 60px;
  display: table;
  color: #fff;
  & .msgSection{
    display: table-cell;
    vertical-align: middle;
    padding-right: 20px;
    & .title {
      font-size: 24px;
      font-family: $mlveda_bold_fontFamily;
      margin-bottom: 10px;
    }
    & .message {
      font-size:  18px;
    }
  }
  & .btnSection {
    display: table-cell;
    vertical-align: middle;
    margin: 0 auto;
    & .Polaris-Button {
      width: 150px;
      background: #fff !important;
      color: $brand-gray1 !important;
      border: 1px solid #fff !important;
    }
    & .Polaris-Button:hover, .Polaris-Button:focus {
      background: #fff !important;
      color: $brand-gray1 !important;
      border: 1px solid #fff !important;
    }
  }
}

.supportCheckoutCurrencies {
  & .Polaris-Stack {
    margin: 0;
    margin-bottom: 15px;
  }
  & .Polaris-Stack__Item {
    width: 50%;
    display: inline-block;
    flex: none;
    margin: 0;
    padding: 3px;
    min-width: 50%;
  }
}

.Polaris-Choice {
  padding: 0.4rem 3px;
}

.Polaris-Popover__Wrapper {
  padding: 10px;
}

.Polaris-Popover__Content {
  height: 100% !important;
  min-width: 150px;
}

.product-filter {
  & .Polaris-Button {
    height: 40px;
  }

  & .Polaris-Button:hover {
    color: #232735 !important; 
    background: linear-gradient(to bottom, white, #f9fafb) !important;
    border: 0.1rem solid var(--p-border, #c4cdd5) !important;
  }
}

.marketForm-main {
  & .Polaris-Banner__Ribbon {
    display: none;
  }
}

.welcome-conatiner {
  & .Polaris-Card {
    border: none;
    & .Polaris-Card__Section {
      padding: 1.5rem 3rem;
    }
  }
}

.more-filters {
  display: inline-block;
  margin-left: 20px;
  & .Polaris-Filters-ConnectedFilterControl__CenterContainer {
    display: none;
  }
  & .Polaris-Filters__TagsContainer {
    display: none;
  }
  & .Polaris-Button--plain {
    display: none;
  }
  & .Polaris-Button:focus {
    background: linear-gradient(to bottom, white, #f9fafb) !important;
    color: #232735 !important;
    border: 0.1rem solid #c4cdd5 !important;
    box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05) !important;
  }
}

.Polaris-Sheet__Container {
  z-index: 9999999 !important;
}

.Polaris-Filters__FilterNodeContainer {
  & .Polaris-Button--plain {
    display: none;
  }
}

.copyMainField .Polaris-Button{
  padding-left: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 4.7rem;
}
