@import "../../styles/_shared";

.largeFontSized {
	font-size: 1.8rem;
  line-height: 3.2rem;
  color: #555;
}

.boldFonts {
	font-family: $mlveda_bold_fontFamily;
}

.appTopBar {
  border-bottom: 1px solid $border-primary;
  height: 57px;
	& .reviewIcon {
		cursor: pointer;
		& img {
			width: 32px;
		}
  }
  & .Polaris-Tabs__Wrapper {
    border-bottom: none;
  }
	& .Polaris-Tabs__Tab {
    // padding: 0 2.4rem;
    padding-left: 0;
    padding-right: 0;

    & .Polaris-Icon {
      width: 1.4rem;
      height: 1.4rem;
    }

    &:not(#{&}--selected) .Polaris-Icon svg {
      fill: #777;
    }
  }

  & .Polaris-Tabs__Title {
    padding-bottom: 1.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    height: 56px;
    & * {
      font-size: 1.2rem;
    }
  }

  & .Polaris-Tabs__Tab--selected {
    .Polaris-Tabs__Title {
      border-bottom-color: $bg-primary;
    }
    & .Polaris-Icon svg {
      fill: $bg-primary;
    }
  }

  & .Polaris-TopBar__SecondaryMenu svg {
    color: var(--p-decorative-three-text, white);
  }

  & .Polaris-TopBar {
    // padding: 0 5%;
    &::after {
      width: unset;
    }
    & .Polaris-TopBar__ContextControl {
      margin-left: 20px;
      width: auto;
      & .Polaris-Stack {
        height: 100%;
        margin: 0;
        & .Polaris-Stack__Item {
          margin-top: 0;
          & .setupContainer-logo {
            width: 40px;
          }
        }
      }
    }

    & .Polaris-TopBar__Contents {
      z-index: 0 !important;
      .Polaris-Icon {
        vertical-align: middle;
      }

      & .Polaris-TopBar__SearchField {
        // width: unset;
        margin: 0;
      }

      & .Polaris-Button {
        // background: white;
        // min-width: 80px;

        & .Polaris-Icon {
          display: block;
        }
      }
    }

    & .Polaris-TopBar__SecondaryMenu {
      white-space: nowrap;
      margin-right: 2rem;
      color: #2da7e9;
      text-decoration: none;
    }
	}

	& .selectedTabText {
		color: $bg-primary;
		// font-weight: 600;
	}
}

.lastStep {
	&__partyImg {
		max-width: 60px;
		width: 100%;
	}

	&__stopFaceHandImg {
		max-width: 50px;
		width: 100%;
		vertical-align: middle;
	}

	&__card {
		max-width: 700px;

		&__coloredText {
			color: #247d6b;
		}

		&__buttonImg {
			max-width: 25px;
			// vertical-align: middle;
		}

		&__button {
			.Polaris-Button,
			.Polaris-Button:focus {
				box-shadow: none;
				padding-top: 4px;
				padding-bottom: 4px;
			}

			&__yes {
				.Polaris-Button {
					&,
					&:hover,
					&:focus {
						background: #45b14d !important;
						border-color: #45b14d !important;
						color: white !important;
					}
					&:focus,
					&:hover {
						opacity: .8;
          }
				}
			}

			&__text {
				font-size: 1.55rem;
				font-weight: 600;
				color: white;
			}

			&__no {
				.Polaris-Button {
					&,
					&:hover,
					&:focus {
						background: #c4e9f2 !important;
						border: 2px solid $bg-primary !important;
						color: $brand-gray1 !important;
					}
					&:hover,
					&:focus {
						opacity: .8;
					}

					.lastStep__card__button__text {
						color: $brand-gray1;
          }
				}
			}
		}

		.Polaris-Card__Section {
			&:first-child {
				padding: 0;

				.lastStep__card__firstSection {
					padding: 0 0;
					display: inline-block;
					width: 100%;
					background: #53d364;
					color: white;
				}
      }

			&:last-child {
				padding-left: 60px;
				padding-right: 60px;

				& > .Polaris-Stack > .Polaris-Stack__Item:last-child {
					width: 100%;
				}
			}
    }
  }

  &__modalBottomText {
    color: #1d8319;
    text-align: center;
  }
}

.setupContainer__appTopBar__container {
  border-bottom: 1px solid $border-primary;
  height: 57px;
  
  & .setupContainer__appTopBar__logo {
    width: 40px;
  }

	& .Polaris-TopBar {
		// padding: 0 5%;

		& .Polaris-TopBar__SearchField {
			margin: 0 auto;
			width: 400px;
		}

		& .setupContainer__appTopBar__progressBar {
			height: 100%;
			background: #4adf83;
			transition: width 2s ease;

			&__container {
				display: block;
				width: 100%;
				height: 4px;
				background: $brand-gray8;
			}
		}

		& .Polaris-TopBar__Contents .Polaris-Button {
			background: white;
			min-width: 80px;

			& .Polaris-Icon {
				display: block;
			}
		}

		& .Polaris-TopBar__SecondaryMenu {
			white-space: nowrap;
			margin-right: 2rem;
			color: #2da7e9;
			text-decoration: none;
		}
	}

	& .Polaris-Icon {
		display: inline-block;
	}

	& .Polaris-DisplayText {
		display: table;
		height: 100%;

		& .Polaris-TextStyle--variationStrong {
			display: table-cell;
			vertical-align: middle;
		}
	}
}

.supportPage {
	&__blueText {
		color: $bg-primary;
	}

	&__ceoImg {
		width: 110px;
		height: 110px;
		border-radius: 55px;
		border: 1px solid $brand-gray8;
	}

	&__container {
		a.supportPage__blueText {
			text-decoration: none;
		}
		.Polaris-Avatar {
			background: $bg-primary;
		}

		.Polaris-Banner--statusWarning {
			border: 1px solid #eec337;
		}

		.Polaris-Banner--statusInfo {
			border: 1px solid #47c1bf;
		}
	}
}

.topBarLogo {
	&__container {
		height: 100%;
    margin: 0;
    cursor: default !important;

		@media (max-width: 800px) {
			.Polaris-Stack__Item:last-child {
				display: none;
			}
		}

		.Polaris-Stack {
			height: 100%;
			margin: 0;

			.Polaris-Stack__Item {
				margin-top: 0;
			}

			.setupContainer__appTopBar__titleText {
				font-family: $mlveda_bold_fontFamily;
			}

			.setupContainer__appTopBar__logo {
				width: 40px;
			}
		}
	}
}

@media (min-width: 40em) {
  .Polaris-DisplayText--sizeLarge {
    font-size: 2.2rem;
  }
}

