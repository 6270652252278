@import 'shared.scss';

.is-size-h1-l{
    @include is-size-h1-l;
  }
  .is-size-h1-r{
    @include is-size-h1-r;
  }
  .is-size-h1-b{
    @include is-size-h1-b;
  }
  
  .is-size-h2-l{
    @include is-size-h2-l;
  }
  .is-size-h2-r{
    @include is-size-h2-r;
  }
  .is-size-h2-b{
    @include is-size-h2-b;
  }
  
  .is-size-h3-l{
    @include is-size-h3-l;
  }
  .is-size-h3-r{
    @include is-size-h3-r;
  }
  .is-size-h3-b{
    @include is-size-h3-b;
  }
  .is-size-h4-xl{
    @include is-size-h4-xl;
  }
  .is-size-h-l{
    @include is-size-h4-l;
  }
  .is-size-h4-r{
    @include is-size-h4-r;
  }
  .is-size-h4-sb{
    @include is-size-h4-sb;
  }
  .is-size-h4-b{
    @include is-size-h4-b;
  }
  
  .is-size-h5-l{
    @include is-size-h5-l;
  }
  .is-size-h5-r{
    @include is-size-h5-r;
  }
  .is-size-h5-sb{
    @include is-size-h5-sb;
  }
  .is-size-h5-b{
    @include is-size-h5-b;
  }
  
  .is-size-h6-l{
    @include is-size-h6-l;
  }
  .is-size-h6-r{
    @include is-size-h6-r;
  }
  .is-size-h6-sb{
    @include is-size-h6-sb;
  }
  .is-size-h6-b{
    @include is-size-h6-b;
  }