@import 'shared.scss';

$card-padding: 25px 25px;

.card-container {
  border: 1px solid $brand-gray8;
  border-radius: 2px;

  & .card-header {
    background-color: $brand-gray2;
    padding: $card-padding;
    border-bottom: 1px solid $brand-gray8;

    & .title {
      color: $brand-gray6;
    }
  }

  & .card-body {
    padding: $card-padding;
    &.no-padding{
      padding: 0px;
    }
    &.y-padding{
      padding: $card-padding;
      padding-left: 0px;
      padding-right: 0px;
    }
    &.x-padding{
      padding: $card-padding;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
  & .card-footer{
    border-top: 1px solid $brand-gray8;
    min-height: 75px;
    padding: 0px 20px;
  }
}